import React from 'react';
import styled, { css } from 'styled-components';

import { getTelephoneLink } from 'libs/content';
import Heading from 'libs/heading';
import LazyImage from 'components/LazyImage';
import { AnchorLink } from 'components/Link';

const PersonWrapper = styled.div`
	display: block;
	width: calc(33.3334% - 30px);
	margin: 15px 15px 30px;
	${p =>
		p.theme.media.smallOnly(css`
			width: calc(50% - 30px);
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			width: 100%;
			margin: 15px 0 30px;
			&:last-of-type {
				margin-bottom: 0;
			}
		`)}
`;

const ImageWrap = styled.div`
	width: 100%;
	.lazy-image {
		width: 100%;
	}
	img {
		border-radius: ${p => p.theme.utils.borderRadius};
	}
`;

const Info = styled.div`
	margin-top: 14px;
	width: 100%;
	padding: 0;
`;

const Name = styled(Heading)`
	margin: 30px 0 10px;
`;

const Position = styled.p`
	margin-bottom: 10px;
`;

const Phone = styled.p`
	display: block;
	margin-bottom: 0px;
`;

const Email = styled.p`
	display: block;
	margin-bottom: 0px;
	a {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

/**
 * Represents person item to use in a grid
 * @param {string} headinglevel - The heading level of the title
 * @param {...object} props - The rest of the properties of the component
 */
export default function PersonItem({ headinglevel = 'h3', ...props }) {
	const smallestImage =
		props?.componentSettings?.personsSmallestImage ||
		props?.componentSettings?.departmentsSmallestImage;

	return (
		<PersonWrapper role="listitem">
			<ImageWrap>
				<LazyImage
					{...props?.image}
					width={smallestImage?.width || props?.image?.width}
					height={smallestImage?.height || props?.image?.height}
					ratio={props?.componentSettings?.imagecrop || '1:1'}
					alt={props?.image?.description || props?.name}
				/>
			</ImageWrap>

			<Info>
				<Name level={headinglevel}>{props?.name}</Name>
				{props?.position && <Position>{props?.position}</Position>}
				<ContactInfo
					name={props?.name}
					phone={props?.phone}
					email={props?.email}
				/>
			</Info>
		</PersonWrapper>
	);
}

/**
 * Represents contact information for a person
 * @param {string} name - The name of the person
 * @param {string} phone - The person´s phone number
 * @param {string} email - The person´s email address
 */
export function ContactInfo({ name, phone, email }) {
	if ((!name && !phone) || (!name && !email)) return;

	return (
		<>
			{phone && (
				<Phone>
					<AnchorLink
						href={getTelephoneLink(phone)}
						title={`Ring ${name}`}>
						{phone}
					</AnchorLink>
				</Phone>
			)}
			{email && (
				<Email>
					<AnchorLink
						href={`mailto:${email}`}
						title={`Send e-post til ${name}`}>
						{email}
					</AnchorLink>
				</Email>
			)}
		</>
	);
}
