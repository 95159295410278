import React from 'react';
import styled, { css } from 'styled-components';
import {
	getComponentSettings,
	removeSpecialCharacters,
	stripHash,
} from 'libs/content';
import MaxWidth from 'layouts/max-width';
import PersonItem, { ContactInfo } from './PersonItem';
import SanitizeHtml from 'libs/SanitizeHtml';
import Spacing from 'layouts/Spacing';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Article from 'components/Article';
import { getSmallestImage } from 'components/LazyImage';

const PersonsList = styled.div`
	margin: 0 -15px;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	${p =>
		p.theme.media.XSmallOnly(css`
			margin: 0;
		`)}
`;

const SinglePersonWrapper = styled.div`
	.component__image-and-content > .spacing {
		padding-bottom: 0 !important;
	}
`;

/**
 * Represents a list of persons in a grid or a large presentation of one person
 * @param {string} title - The title of the component
 * @param {object} intro - The introduction text of the component
 * @param {array} departments - The departments to show persons from
 * @param {array} persons - The persons to show
 * @param {string} headinglevel - The heading level of the title
 * @param {array} settings - The settings of the component
 * @param {string} anchorId - The id of the component
 * @param {...object} props - The rest of the parameters for the component
 */
export default function Persons({
	title = '',
	intro = {},
	departments = [],
	persons = [],
	headinglevel = 'h2',
	settings = [],
	anchorId = '',
	...props
}) {
	const componentSettings = getComponentSettings({ settings, props });

	const firstPerson = (persons?.length === 1 && persons[0]) || {};

	// find the smallest image width in the persons array
	componentSettings.personsSmallestImage = getSmallestImage(persons);

	// find the smallest image width in the departments persons array
	componentSettings.departmentsSmallestImage = getSmallestImage(
		departments?.flatMap(d => d)
	);

	return (
		<Spacing
			className="component__persons"
			data-cy="component__persons"
			{...props}
			id={
				(anchorId && stripHash(anchorId)) ||
				(title && removeSpecialCharacters(title)) ||
				`personer-${Math.floor(Math.random() * 100)}`
			}>
			<MaxWidth>
				{departments?.length > 0 && (
					<div>
						{departments.map((department, i) => {
							if (!department?.persons?.length > 0) return null;

							return (
								<div
									key={`${removeSpecialCharacters(
										department?.navn
									)}-${i}`}>
									{componentSettings?.hidetitle !==
										'true' && (
										<TitleAndText
											title={department?.name}
											text={department?.desc}
											headinglevel={headinglevel}
											settings={settings}
											nested={true}
										/>
									)}

									<PersonsList role="list">
										{department?.persons?.map((p, i) => (
											<PersonItem
												key={`${removeSpecialCharacters(
													p?.name
												)}-${i}`}
												componentSettings={
													componentSettings
												}
												{...p}
											/>
										))}
									</PersonsList>
								</div>
							);
						})}
					</div>
				)}

				{persons?.length > 0 && (
					<div>
						{componentSettings?.hidetitle !== 'true' && title && (
							<TitleAndText
								title={title}
								text={intro}
								headinglevel={headinglevel}
								settings={settings}
								nested={true}
							/>
						)}
						{persons.length === 1 ? (
							<SinglePersonWrapper>
								<Article
									key={firstPerson?.id}
									title={firstPerson?.name}
									stickTitle={firstPerson?.position}
									image={
										firstPerson?.environmentImage ||
										firstPerson.image
									}
									direction="horizontal"
									headinglevel="h2"
									component={
										<>
											<SanitizeHtml
												html={
													firstPerson?.desc
														?.childMarkdownRemark
														?.html
												}
											/>
											<ContactInfo {...firstPerson} />
										</>
									}
								/>
							</SinglePersonWrapper>
						) : (
							<PersonsList role="list">
								{persons.map(person => (
									<PersonItem
										key={removeSpecialCharacters(
											person.name
										)}
										componentSettings={componentSettings}
										{...person}
									/>
								))}
							</PersonsList>
						)}
					</div>
				)}
			</MaxWidth>
		</Spacing>
	);
}
